


























































































































































































































































































































































































































































































































































































































































































































































.edit-broadcast {
  .edit-broadcast-form {
    max-width: 630px;
  }
  .flatpickr-input {
    background: #FFF !important;
  }
}
